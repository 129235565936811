const { trackEvent } = require('./tracking');

const ACTION = {
  ADD: 'add',
  REMOVE: 'remove',
};

const getTracks = (appearance, itemId) => {
  const layoutChildren = appearance?.layout?.children;
  const layoutCarousel = layoutChildren?.find(child => child.component === 'DynamicCarousels');

  let collections;
  if (layoutCarousel) {
    collections = layoutCarousel.children?.filter(child => child.component === 'Collection');
  } else {
    collections = layoutChildren?.filter(child => child.component === 'Collection');
  }

  if (collections && collections.length > 0) {
    for (let i = 0; i < collections.length; i += 1) {
      const { children, properties } = collections[i];
      const { tracks } = properties;

      const collectionHasItem = children.some((eshopItem) => eshopItem.id === itemId);

      if (collectionHasItem && tracks) {
        tracks.position = children.findIndex((eshopItem) => eshopItem.id === itemId);
        return tracks;
      }
    }
  }

  return null;
};

/* eslint-disable camelcase */
const dispatchAddToCardShopsInfo = (trackingMediator, trackingData) => {
  const {
    item_id,
    variation_id,
    price,
    title,
    currency,
    brand,
    name,
    quantity,
  } = trackingData;

  trackingMediator('dispatch', 'item:info', {
    item_id,
    variation_id,
    price,
    title,
    brand,
    name,
    quantity,
    currency,
  });
};

const getFormattedTrackingData = (trackingData, variationId) => {
  const { currency, price, title, item_id, brand, name, quantity } = trackingData;

  return {
    currency,
    price,
    title,
    item_id,
    variation_id: variationId,
    brand,
    quantity,
    name,
  };
};
/* eslint-enable camelcase */

const sendTrack = (action) => (tracks, extraInfo) => {
  if (tracks) {
    const { melidata_track: melidataTracks, paths, position } = tracks;

    melidataTracks.path = paths[action];

    trackEvent({ melidata_event: melidataTracks }, { ...extraInfo, position });
  }
};

const sendAddTrack = sendTrack(ACTION.ADD);
const sendRemoveTrack = sendTrack(ACTION.REMOVE);

module.exports = {
  getTracks,
  sendAddTrack,
  sendRemoveTrack,
  dispatchAddToCardShopsInfo,
  getFormattedTrackingData,
};
